import React from "react"
import "../wdyr"
import SEO from "../components/seo"
import { useQuery } from "react-query"

const CookiePolicyPage = () => {
  const {
    data
  } = useQuery("cookiepolicy", () =>
    fetch(`${process.env.API_URL}/${process.env.EVENT_ID}/staticpage/cookiepolicy`).then(res =>
      res.json()
    )
  )
  return (
    <>
      <SEO title="Cookie Policy" />

      <div className="container-fluid">
        <div className="page">
          <h1 className="text-size-h3 font-primary-bold mb-35">
            Cookie Policy
          </h1>
          <div
            dangerouslySetInnerHTML={{
              __html: data?.result?.content,
            }}
          />
        </div>
      </div>
    </>
  )
}

export default CookiePolicyPage
